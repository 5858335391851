*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --mui-palette-layout-text-color: #1b1e3d;
  --mui-palette-layout-border-color: rgb(27 30 61 / 10%);
  --mui-palette-layout-background-color: #fff;

  --mui-palette-options-text-color: #1b1e3d;
  --mui-palette-options-active-color: #6270c1;
  --mui-palette-options-hover-default-color: #b4b6c2;
  --mui-palette-options-default-color: #b4b6c2;
  --mui-palette-options-light-active-background-color: #f7f8f9;
  --mui-palette-options-active-background-color: #eff0f9;
  --mui-palette-options-hover-background-color: #e1e2e6;
  --mui-palette-options-background-color: #f7f8f9;
  --mui-palette-options-active-border-color: rgb(98 112 193 / 20%);
  --mui-palette-options-border-color: rgb(180 182 194 / 50%);
  --mui-palette-options-corners-rounding: 6px;

  --mui-palette-input_fields-background-color: #fff;
  --mui-palette-input_fields-text-color: #1b1e3d;
  --mui-palette-input_fields-placeholder-text-color: #b4b6c2;

  --mui-palette-buttons-background-color: #6270c1;
  --mui-palette-buttons-background-color-100: #6270c1;
  --mui-palette-buttons-text-color: #ffffff;
  --mui-palette-buttons-background-color-20: #e0e2f6;
  --mui-palette-buttons-background-color-30: #cfd4ec;
  --mui-palette-buttons-background-color-80: #7b86db;
  --mui-palette-buttons-background-color-200: #6270c1;
  --mui-palette-buttons-corners-rounding: 6px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.footer-links {
  opacity: 0.8;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444a63;
}

.spinner-wrapper {
  color: #b5b8c0;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  /* margin: 72px auto; */
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.report-page .ag-theme-balham .ag-popup {
  height: 0 !important;
  min-height: 0 !important;
}

.report-page .ag-theme-balham {
  height: calc(100% - 32px) !important;
  --ag-balham-active-color: #f3f4fa !important;
  --ag-foreground-color: #1b1d3f !important;
  --ag-background-color: #fcfcfd !important;
  --ag-header-background-color: #fff !important;
  --ag-border-color: #f2f2f5 !important;
  --ag-odd-row-background-color: #fff !important;
  --ag-row-hover-color: #fcfcfd !important;
  --ag-input-border-color: #f2f2f5 !important;
  --ag-secondary-foreground-color: #494c65 !important;
  --ag-row-border-color: #f2f2f5 !important;
  --ag-range-selection-background-color: rgb(113 130 141 / 20%) !important;
  --ag-selected-row-background-color: #f3f4fa !important;
}

.report-page .ag-grid-bulham input:focus {
  border-color: #719ece;
}

.report-page .ag-root-wrapper {
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: transparent !important;
}

.monitoring .ag-theme-balham {
  --ag-balham-active-color: #f3f4fa !important;
  --ag-foreground-color: #1b1d3f !important;
  --ag-background-color: #fcfcfd !important;
  --ag-header-background-color: #fff !important;
  --ag-border-color: #f2f2f5 !important;
  --ag-odd-row-background-color: #fff !important;
  --ag-row-hover-color: #fcfcfd !important;
  --ag-input-border-color: #f2f2f5 !important;
  --ag-secondary-foreground-color: #494c65 !important;
  --ag-row-border-color: #f2f2f5 !important;
  --ag-range-selection-background-color: rgb(113 130 141 / 20%) !important;
  --ag-selected-row-background-color: #f3f4fa !important;
}

.monitoring .ag-grid-bulham input:focus {
  border-color: #719ece;
}

.monitoring .ag-root-wrapper {
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.monitoring .monitoring-table-pagination {
  border-color: #f2f2f5;
}

.monitoring .ag-paging-panel {
  justify-content: center;
}

/* Temp */

.full {
  grid-template-areas:
    "first second"
    "third fourth";
}

.h-two {
  grid-template-areas:
    "first second"
    "third third";
}

.h-one {
  grid-template-areas:
    "first first"
    "second third";
}

.v-two {
  grid-template-areas:
    "first third"
    "second third";
}

.v-one {
  grid-template-areas:
    "first second"
    "first third";
}

.h-duo {
  grid-template-areas:
    "first first"
    "second second";
}

.v-duo {
  grid-template-areas:
    "first second"
    "first second";
}

.solo {
  grid-template-areas:
    "first first"
    "first first";
}

/* Apply the grid areas to the sections */
.page-component-section:nth-child(1) {
  grid-area: first;
}

.page-component-section:nth-child(2) {
  grid-area: second;
}

.page-component-section:nth-child(3) {
  grid-area: third;
}

.page-component-section:nth-child(4) {
  grid-area: fourth;
}
